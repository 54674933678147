import * as React from 'react';

const CTA = () => (
  <article className="w-full max-w-5xl pt-12 mx-auto my-auto">
    <div className="flex">
      <div className="p-3 -m-3 overflow-hidden rounded-lg cta">
        <h1 className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
          Businesses we <br />
          <span className="text-brand-orange">work with</span>
        </h1>
        <div className="mt-6 prose text-gray-900">
          <p>
            Frontline Removals works with a lot of local and interstate
            companies, below we have listed a few that we work with regularly
            and recommend their services to all as they are trusted companies
            and experts in their field!
          </p>
          <p>
            If you are a company that works with Frontline Removals and we don't
            have you on this list, please send an email with your headshot and
            business logo with a small about text so we can add you below.
          </p>
        </div>
      </div>
    </div>
  </article>
);

export { CTA };
